<template>
  <Dialog
    :visible="visible"
    :header="exibirHeader()"
    :closeOnEscape="!saving && !confirmDialog"
    :closable="!saving"
    :modal="true"
    :containerStyle="{ width: '70vw' }"
    class="p-fluid"
    @show="onShow()"
    @hide="onHide()"
    @update:visible="fechar()">
    <BlockUI :blocked="saving">
      <Card>
        <template #content>
          <form @submit.prevent="salvar()">
            <div class="p-fluid formgrid grid">
              <div class="field col-12 md:col-6">
                <label for="nome" class="required">Nome</label>
                <InputText
                  id="nome"
                  v-model="vinculo.nome"
                  type="text"
                  :class="{
                    'p-invalid': submitted && v$.vinculo.nome.$invalid,
                  }" />
                <div
                  v-if="submitted && v$.vinculo.nome.required.$invalid"
                  class="p-error">
                  O campo Nome é obrigatório.
                </div>
              </div>
            </div>
          </form>
        </template>
      </Card>
    </BlockUI>
    <template #footer>
      <Button
        :disabled="saving"
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-text"
        @click="fechar()" />
      <Button :disabled="saving" autofocus @click="salvar">
        <span v-if="!saving" class="pi pi-check"></span>
        <span v-if="saving" class="pi pi-spin pi-spinner"></span>
        <span v-if="!saving" class="ml-2">Salvar</span>
        <span v-if="saving" class="ml-2">Aguarde</span>
      </Button>
    </template>
  </Dialog>
</template>

<script>
import UseVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import Vinculo from '@/domain/Vinculo.js'
import VinculoService from '@/service/VinculoService.js'

export default {
  props: {
    visible: { type: Boolean, required: true },
    update: { type: Boolean, required: true },
    propsvinculo: {
      type: Object,
      required: false,
      default: new Vinculo(),
    },
  },
  setup() {
    return { v$: UseVuelidate() }
  },

  data() {
    return {
      vinculo: new Vinculo(),
      saving: false,
      entidades: null,
      confirmDialog: false,
      id: this.$route.params.id,
      submitted: false,
    }
  },

  created() {
    this.vinculoService = new VinculoService(this.$http)

    if (this.id) {
      this.vinculoService.getVinculo(this.id).then((res) => {
        this.vinculo = res
      })
    }
  },

  validations() {
    return {
      vinculo: {
        nome: { required },
      },
    }
  },

  methods: {
    exibirHeader() {
      return this.update ? 'Editar Vínculo' : 'Novo Vículo'
    },

    fechar() {
      this.$emit('update:visible', false)
    },

    onShow() {
      this.vinculo = JSON.parse(JSON.stringify(this.propsvinculo))
    },

    onHide() {},

    validate() {
      this.submitted = true
      this.v$.vinculo.$touch()
      if (this.v$.vinculo.$invalid) {
        return false
      }
      return true
    },

    salvar() {
      if (this.validate()) {
        this.saving = true
        this.vinculoService
          .saveVinculo(this.vinculo)
          .then(() => {
            this.$toast.add({
              severity: 'success',
              summary: 'Vínculo criado com sucesso.',
              life: 10000,
            })
          })
          .catch((err) => {
            this.$toast.add({
              severity: 'error',
              summary: err.response.data.message,
              life: 10000,
            })
          })
          .finally(() => {
            this.saving = false
            this.$emit('carregarDados')
            this.fechar()
          })
      }
    },
  },
}
</script>

<style scoped>
.required:after {
  content: ' *';
  color: red;
}
</style>
