export default class VinculoService {
  constructor(http) {
    this._http = http
  }

  async getVinculos(dados) {
    const { data } = await this._http.get(
      `/api/vinculos/${dados.page}/${dados.rows}`,
    )
    return data
  }

  async getListaVinculos() {
    const { data } = await this._http.get(`/api/vinculos/list`)
    return data
  }

  async saveVinculo(vinculo) {
    if (vinculo.id) {
      return await this._http.put('/api/vinculos', vinculo)
    } else {
      return await this._http.post('/api/vinculos', vinculo)
    }
  }

  async deleteVinculo(consignataria) {
    await this._http.delete(`/api/vinculos/${consignataria.id}`)
  }

  async getVinculo(id) {
    const { data } = await this._http.get(`/api/vinculos/${id}`)
    return data
  }
}
